var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","overflow":"auto"}},[_c('el-form',{ref:"form",staticStyle:{"height":"90%","overflow":"auto"},attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('AssociationScroll',[_c('div',{staticClass:"steps-right",attrs:{"slot":"tabs-down"},slot:"tabs-down"},[_c('div',{staticClass:"steps-right-title"},[_vm._v("修改记录")]),_c('div',{staticClass:"steps-right-record"},_vm._l((_vm.form.insServiceFlowList),function(flow){return _c('div',{staticClass:"steps-right-record-item",staticStyle:{"min-height":"50px"}},[_c('div',{staticClass:"steps-right-record-item-bar"}),_c('div',{staticClass:"steps-right-record-item-content",staticStyle:{"font-size":"12px","color":"#333333"}},[_c('div',{staticStyle:{"font-weight":"300"}},[_vm._v(_vm._s(flow.recordTime))]),_c('div',{staticStyle:{"font-weight":"400","margin-top":"6px"}},[_vm._v(" "+_vm._s(flow.recorderName)+" "+_vm._s(flow.description)+" ")])])])}),0)]),_c('AssociationScrollView',{attrs:{"title":"保单信息","name":"0"}},[_c('TextInputContainer',[_c('el-form-item',{attrs:{"prop":"insuredName"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"querySearchAsync":_vm.querySearchAsync,"config":{
                type: 'searchInput',
                required: true,
                label: '被保险人名称：',
              }},on:{"handleSelectQuerySearchAsync":_vm.handleSelectSearchAsync},model:{value:(_vm.form.insuredName),callback:function ($$v) {_vm.$set(_vm.form, "insuredName", $$v)},expression:"form.insuredName"}})],1),_c('el-form-item',{attrs:{"prop":"firmAddress"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                type: 'input',
                required: true,
                label: '被保险人地址:',
                placeholder: '系统自动填写',
                disabled: true,
              }},model:{value:(_vm.form.firmAddress),callback:function ($$v) {_vm.$set(_vm.form, "firmAddress", $$v)},expression:"form.firmAddress"}})],1),_c('el-form-item',{attrs:{"prop":"policyId"}},[(_vm.isEdit)?_c('TextInput',{attrs:{"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              },"config":{
                type: 'select',
                option: _vm.dictList.policyNoList,
                modelTextKey: 'policyNo',
                required: true,
                label: '保单号码:',
              }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.form.policyId),callback:function ($$v) {_vm.$set(_vm.form, "policyId", $$v)},expression:"form.policyId"}}):_c('TextInput',{attrs:{"isText":"","config":{
                type: 'input',
                required: true,
                label: '保单号码:',
                disabled: true,
              }},model:{value:(_vm.form.policyNo),callback:function ($$v) {_vm.$set(_vm.form, "policyNo", $$v)},expression:"form.policyNo"}})],1),_vm._l((_vm.baseItemsConfig),function(config,index){return _c('el-form-item',{key:index,attrs:{"prop":config.modelKey}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":config,"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.form[config.modelKey]),callback:function ($$v) {_vm.$set(_vm.form, config.modelKey, $$v)},expression:"form[config.modelKey]"}})],1)})],2)],1),_c('AssociationScrollView',{attrs:{"title":"服务信息","name":"1"}},[_c('TextInputContainer',[_c('el-form-item',{attrs:{"prop":"serviceTypeKey"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '服务种类：',
                type: 'select',
                modelKey: 'serviceTypeKey',
                option: this.dictList.sdServiceTypes,
                modelTextKey: 'serviceTypeName',
              },"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              }},model:{value:(_vm.form.serviceTypeKey),callback:function ($$v) {_vm.$set(_vm.form, "serviceTypeKey", $$v)},expression:"form.serviceTypeKey"}})],1),_c('el-form-item',{attrs:{"prop":"serviceProjectKey"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '服务项目：',
                type: 'select',
                modelKey: 'serviceProjectKey',
                option: this.dictList.insServicePlanList,
                modelTextKey: 'serviceProjectName',
              },"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.form.serviceProjectKey),callback:function ($$v) {_vm.$set(_vm.form, "serviceProjectKey", $$v)},expression:"form.serviceProjectKey"}})],1),_c('el-form-item',{attrs:{"prop":"serviceAmount"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '服务金额：',
                type: 'input',
                isNum: true,
                modelKey: 'serviceAmount',
              }},model:{value:(_vm.form.serviceAmount),callback:function ($$v) {_vm.$set(_vm.form, "serviceAmount", $$v)},expression:"form.serviceAmount"}})],1)],1),_c('el-form-item',{attrs:{"prop":"serviceStandard"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              label: '服务标准：',
              type: 'textarea',
              modelKey: 'serviceStandard',
              width: '100%',
            }},model:{value:(_vm.form.serviceStandard),callback:function ($$v) {_vm.$set(_vm.form, "serviceStandard", $$v)},expression:"form.serviceStandard"}})],1),_c('TextInputContainer',[_c('el-form-item',{attrs:{"prop":"expertId"}},[_c('TextInput',{attrs:{"querySearchAsync":_vm.querySearchAsyncExpert,"isText":!_vm.isEdit,"config":{
                label: '指派专家：',
                type: 'searchInput',
                required: true,
                modelKey: 'expertId',
                modelTextKey: 'designateExpert',
              }},on:{"handleSelectQuerySearchAsync":_vm.handleSelectSearchAsyncExpert},model:{value:(_vm.form.designateExpert),callback:function ($$v) {_vm.$set(_vm.form, "designateExpert", $$v)},expression:"form.designateExpert"}})],1),_c('el-form-item',{attrs:{"prop":"serviceLocation"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '专家服务区域：',
                type: 'input',
                disabled: true,
                placeholder: '系统自动填写',
                modelKey: 'serviceLocation',
              }},model:{value:(_vm.form.serviceLocation),callback:function ($$v) {_vm.$set(_vm.form, "serviceLocation", $$v)},expression:"form.serviceLocation"}})],1),_c('el-form-item',{attrs:{"prop":"areasOfExpertise"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '专家擅长领域：',
                type: 'input',
                disabled: true,
                placeholder: '系统自动填写',
                modelKey: 'areasOfExpertise',
              }},model:{value:(_vm.form.areasOfExpertise),callback:function ($$v) {_vm.$set(_vm.form, "areasOfExpertise", $$v)},expression:"form.areasOfExpertise"}})],1),_c('el-form-item',{attrs:{"prop":"workContent"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '专家擅长工作内容：',
                type: 'input',
                disabled: true,
                placeholder: '系统自动填写',
                modelKey: 'workContent',
              }},model:{value:(_vm.form.workContent),callback:function ($$v) {_vm.$set(_vm.form, "workContent", $$v)},expression:"form.workContent"}})],1),_c('el-form-item',{attrs:{"prop":"contracts"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '委托方：',
                type: 'input',
                modelKey: 'contracts',
              }},model:{value:(_vm.form.contracts),callback:function ($$v) {_vm.$set(_vm.form, "contracts", $$v)},expression:"form.contracts"}})],1),_c('el-form-item',{attrs:{"prop":"institution"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '机构：',
                type: 'input',
                modelKey: 'institution',
              }},model:{value:(_vm.form.institution),callback:function ($$v) {_vm.$set(_vm.form, "institution", $$v)},expression:"form.institution"}})],1),_c('el-form-item',{attrs:{"prop":"serviceDate"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '期望服务时间：',
                type: 'datePicker',
                required: true,
                modelKey: 'serviceDate',
              }},model:{value:(_vm.form.serviceDate),callback:function ($$v) {_vm.$set(_vm.form, "serviceDate", $$v)},expression:"form.serviceDate"}})],1),_c('el-form-item',{attrs:{"prop":"expertServiceAmount"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '专家服务金额：',
                type: 'input',
                modelKey: 'expertServiceAmount',
              }},model:{value:(_vm.form.expertServiceAmount),callback:function ($$v) {_vm.$set(_vm.form, "expertServiceAmount", $$v)},expression:"form.expertServiceAmount"}})],1),_c('el-form-item',{attrs:{"prop":"serviceTypesOfKey"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '服务类型：',
                type: 'select',
                modelKey: 'serviceTypesOfKey',
                modelTextKey: 'serviceTypesOfValue',
                option: this.dictList.ServiceTypesOf,
              },"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.form.serviceTypesOfKey),callback:function ($$v) {_vm.$set(_vm.form, "serviceTypesOfKey", $$v)},expression:"form.serviceTypesOfKey"}})],1),_c('el-form-item',{attrs:{"prop":"serviceAddress"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '服务地址：',
                type: 'input',
                modelKey: 'serviceAddress',
              }},model:{value:(_vm.form.serviceAddress),callback:function ($$v) {_vm.$set(_vm.form, "serviceAddress", $$v)},expression:"form.serviceAddress"}})],1)],1),_c('el-form-item',{attrs:{"prop":"taskDescription"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              label: '任务描述：',
              type: 'textarea',
              modelKey: 'taskDescription',
              width: '100%',
            }},model:{value:(_vm.form.taskDescription),callback:function ($$v) {_vm.$set(_vm.form, "taskDescription", $$v)},expression:"form.taskDescription"}})],1),_c('el-form-item',{attrs:{"prop":"serviceInfoRemark"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              label: '备注：',
              type: 'textarea',
              modelKey: 'serviceInfoRemark',
              width: '100%',
            }},model:{value:(_vm.form.serviceInfoRemark),callback:function ($$v) {_vm.$set(_vm.form, "serviceInfoRemark", $$v)},expression:"form.serviceInfoRemark"}})],1)],1),(!_vm.isEdit)?_c('div',[_c('AssociationScrollView',{attrs:{"title":"企业基本信息","name":"2"}},[_c('TextInputContainer',[_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '企业位置情况：',
                  type: 'input',
                }},model:{value:(_vm.form.firmBasicInfo.positionConditionValue),callback:function ($$v) {_vm.$set(_vm.form.firmBasicInfo, "positionConditionValue", $$v)},expression:"form.firmBasicInfo.positionConditionValue"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '企业性质：',
                  type: 'input',
                }},model:{value:(_vm.form.firmBasicInfo.natureValue),callback:function ($$v) {_vm.$set(_vm.form.firmBasicInfo, "natureValue", $$v)},expression:"form.firmBasicInfo.natureValue"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '企业人员规模(含劳务人员)：',
                  type: 'input',
                }},model:{value:(_vm.form.firmBasicInfo.naturePeopleNumber),callback:function ($$v) {_vm.$set(_vm.form.firmBasicInfo, "naturePeopleNumber", $$v)},expression:"form.firmBasicInfo.naturePeopleNumber"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '企业主要业务/项目名称：',
                  type: 'input',
                }},model:{value:(_vm.form.firmBasicInfo.mainBusiness),callback:function ($$v) {_vm.$set(_vm.form.firmBasicInfo, "mainBusiness", $$v)},expression:"form.firmBasicInfo.mainBusiness"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '企业行业类型：',
                  type: 'input',
                }},model:{value:(_vm.form.firmBasicInfo.industryName),callback:function ($$v) {_vm.$set(_vm.form.firmBasicInfo, "industryName", $$v)},expression:"form.firmBasicInfo.industryName"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '企业实际员工人数：',
                  type: 'input',
                }},model:{value:(_vm.form.firmBasicInfo.actualPeopleNumber),callback:function ($$v) {_vm.$set(_vm.form.firmBasicInfo, "actualPeopleNumber", $$v)},expression:"form.firmBasicInfo.actualPeopleNumber"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '重点监管类型：',
                  type: 'input',
                }},model:{value:(_vm.form.firmBasicInfo.superviseTypeValue),callback:function ($$v) {_vm.$set(_vm.form.firmBasicInfo, "superviseTypeValue", $$v)},expression:"form.firmBasicInfo.superviseTypeValue"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '企业其他情况描述：',
                  type: 'textarea',
                  modelKey: 'taskDescription',
                  width: '100%',
                }},model:{value:(_vm.form.firmBasicInfo.otherDescription),callback:function ($$v) {_vm.$set(_vm.form.firmBasicInfo, "otherDescription", $$v)},expression:"form.firmBasicInfo.otherDescription"}})],1)],1)],1)],1):_vm._e(),(!_vm.isEdit)?_c('div',[_c('AssociationScrollView',{attrs:{"title":"签到信息","name":"3"}},[_c('TextInputContainer',[_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '签到状态：',
                  type: 'input',
                }},model:{value:(_vm.form.signInStatusName),callback:function ($$v) {_vm.$set(_vm.form, "signInStatusName", $$v)},expression:"form.signInStatusName"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '签到时间：',
                  type: 'input',
                }},model:{value:(_vm.form.signInTime),callback:function ($$v) {_vm.$set(_vm.form, "signInTime", $$v)},expression:"form.signInTime"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '签到地点：',
                  type: 'input',
                }},model:{value:(_vm.form.signInLocations),callback:function ($$v) {_vm.$set(_vm.form, "signInLocations", $$v)},expression:"form.signInLocations"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '签到图片：',
                  type: 'slot',
                }},model:{value:(_vm.form.signInPicture),callback:function ($$v) {_vm.$set(_vm.form, "signInPicture", $$v)},expression:"form.signInPicture"}},[_c('div',[(_vm.form.signInPicture)?_c('img',{staticStyle:{"width":"104px","height":"104px"},attrs:{"src":_vm.form.signInPicture}}):_c('div',[_vm._v("暂时没有图片！")])])])],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '签出时间：',
                  type: 'input',
                }},model:{value:(_vm.form.signOutTime),callback:function ($$v) {_vm.$set(_vm.form, "signOutTime", $$v)},expression:"form.signOutTime"}})],1),_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                  label: '签出地点：',
                  type: 'input',
                }},model:{value:(_vm.form.signOutLocations),callback:function ($$v) {_vm.$set(_vm.form, "signOutLocations", $$v)},expression:"form.signOutLocations"}})],1)],1)],1)],1):_vm._e(),(!_vm.isEdit)?_c('div',[_c('AssociationScrollView',{attrs:{"title":"服务报告","name":"4"}},[_c('FormListUpload',{attrs:{"isEdit":false,"title":"服务报告","attachmentType":"sdServiceFile"},model:{value:(_vm.form.attachmentAddReqList),callback:function ($$v) {_vm.$set(_vm.form, "attachmentAddReqList", $$v)},expression:"form.attachmentAddReqList"}})],1)],1):_vm._e(),(!_vm.isEdit)?_c('div',[_c('AssociationScrollView',{attrs:{"title":"备注","name":"5"}},[_c('el-form-item',[_c('TextInput',{attrs:{"isText":"","config":{
                label: '备注：',
                type: 'textarea',
                modelKey: 'remark',
                width: '100%',
              }},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1),_c('FormListUpload',{attrs:{"isEdit":false,"title":"附件","attachmentType":"sdServiceFile"},model:{value:(_vm.form.remarkAttachment),callback:function ($$v) {_vm.$set(_vm.form, "remarkAttachment", $$v)},expression:"form.remarkAttachment"}})],1)],1):_vm._e(),(!_vm.isEdit && _vm.form.reportStatus != 1)?_c('div',[_c('AssociationScrollView',{attrs:{"title":"审核","name":"6"}},[_c('TextInput',{attrs:{"config":{
              disabled: _vm.form.reportStatus != 2,
              label: '审核结果：',
              type: 'radio',
              modelKey: 'examineResult',
              modelTextKey: 'examineResult',
              option: [
                { dictKey: 1, dictValue: '通过' },
                { dictKey: 2, dictValue: '退回' },
              ],
            },"optionConfig":{
              value: 'dictKey',
              label: 'dictValue',
            }},model:{value:(_vm.form.examineResult),callback:function ($$v) {_vm.$set(_vm.form, "examineResult", $$v)},expression:"form.examineResult"}}),_c('TextInput',{attrs:{"config":{
              disabled: _vm.form.reportStatus != 2,
              label: '备注：',
              type: 'textarea',
              modelKey: 'examineRemark',
              width: '100%',
            }},model:{value:(_vm.form.examineRemark),callback:function ($$v) {_vm.$set(_vm.form, "examineRemark", $$v)},expression:"form.examineRemark"}})],1)],1):_vm._e()],1)],1),_c('div',{staticClass:"sd-bottom-btn"},[_c('el-button',{on:{"click":_vm.back}},[_vm._v("返回")]),(_vm.isEdit)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("保存")]):_vm._e(),(_vm.form.reportStatus == 2 && !_vm.isEdit)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("保存")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }