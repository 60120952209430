// 服务种类
export const sdServiceTypes = [
  { dictKey: 1, dictValue: "工贸安责险服务" },
  { dictKey: 2, dictValue: "建工安责险服务" },
  { dictKey: 3, dictValue: "交通安责险服务" },
];
// 交通安责险行业字典
export const sdTrafficIndustry = [
  { dictKey: "", dictValue: "全部" },
  { dictKey: 1, dictValue: "道路运输" },
  { dictKey: 2, dictValue: "水路运输" },
  { dictKey: 3, dictValue: "港口码头" },
  { dictKey: 4, dictValue: "维修" },
  { dictKey: 5, dictValue: "建设工程" },
];
// 交通安责险服务类型字典
export const sdTrafficServiceType = [
  { dictKey: "", dictValue: "全部" },
  { dictKey: 0, dictValue: "其他" },
  { dictKey: 1, dictValue: "隐患排查" },
  { dictKey: 2, dictValue: "应急演练" },
  { dictKey: 3, dictValue: "安全培训" },
];

// 交通安责险车辆类型字典
export const sdTrafficVehicleType = [
  { dictKey: "", dictValue: "全部" },
  { dictKey: 0, dictValue: "其他" },
  { dictKey: 1, dictValue: "渣土车" },
  { dictKey: 2, dictValue: "搅拌车" },
  { dictKey: 3, dictValue: "货车" },
  { dictKey: 4, dictValue: "出租车或网约车" },
  { dictKey: 5, dictValue: "公交车" },
  { dictKey: 5, dictValue: "大客车" },
];

// 交通安责险服务处理状态字典
export const sdTrafficServiceStatus = [
  { dictKey: "", dictValue: "全部" },
  { dictKey: 0, dictValue: "未处理" },
  { dictKey: 1, dictValue: "已服务" },
  { dictKey: 2, dictValue: "已取消" },
];

// 交通安责险服务运输类型字典
export const sdTrafficTransportType = [
  { dictKey: "", dictValue: "全部" },
  { dictKey: 1, dictValue: "客运" },
  { dictKey: 2, dictValue: "普通货运" },
  { dictKey: 3, dictValue: "危险品货运" },
];
