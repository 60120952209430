<template>
  <serviceForm
    :isEdit="$route.params.isEdit"
    :detailData="detailData"
  ></serviceForm>
</template>
<script>
import serviceForm from "./serviceForm.vue";
import { getServiceDetail } from "@/api/safeDuty.js";
export default {
  components: { serviceForm },
  data() {
    return {
      detailData: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getDetail(this.$route.params.id);
    } else {
      this.detailData = null;
    }
  },
  methods: {
    async getDetail(id = "") {
      let res = await getServiceDetail({
        id: this.$route.params.id,
      });
      this.detailData = res.data;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>
