<template>
  <div style="height: 100%; overflow: auto">
    <el-form
      style="height: 90%; overflow: auto"
      :model="form"
      :rules="rules"
      ref="form"
    >
      <AssociationScroll>
        <div slot="tabs-down" class="steps-right">
          <!-- 标题 -->
          <div class="steps-right-title">修改记录</div>
          <!-- 修改记录 -->
          <div class="steps-right-record">
            <div
              v-for="flow in form.insServiceFlowList"
              class="steps-right-record-item"
              style="min-height: 50px"
            >
              <div class="steps-right-record-item-bar"></div>
              <div
                class="steps-right-record-item-content"
                style="font-size: 12px; color: #333333"
              >
                <div style="font-weight: 300">{{ flow.recordTime }}</div>
                <div style="font-weight: 400; margin-top: 6px">
                  {{ flow.recorderName }} {{ flow.description }}
                </div>
              </div>
            </div>
            <!-- <div class="steps-right-record-item">
                <div class="steps-right-record-item-bar"></div>
                <div class="steps-right-record-item-content">
                  ahofihsodfiosdhfiosadfoshdfiosaydfosagdhfosdhfosydfo
                </div>
              </div> -->
          </div>
        </div>
        <AssociationScrollView title="保单信息" name="0">
          <TextInputContainer>
            <el-form-item prop="insuredName">
              <TextInput
                :isText="!isEdit"
                @handleSelectQuerySearchAsync="handleSelectSearchAsync"
                :querySearchAsync="querySearchAsync"
                v-model="form.insuredName"
                :config="{
                  type: 'searchInput',
                  required: true,
                  label: '被保险人名称：',
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item prop="firmAddress">
              <TextInput
                :isText="!isEdit"
                v-model="form.firmAddress"
                :config="{
                  type: 'input',
                  required: true,
                  label: '被保险人地址:',
                  placeholder: '系统自动填写',
                  disabled: true,
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item prop="policyId">
              <TextInput
                v-if="isEdit"
                @returnVal="getKeyToValue"
                v-model="form.policyId"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :config="{
                  type: 'select',
                  option: dictList.policyNoList,
                  modelTextKey: 'policyNo',
                  required: true,
                  label: '保单号码:',
                }"
              >
              </TextInput>
              <TextInput
                v-else
                isText
                v-model="form.policyNo"
                :config="{
                  type: 'input',
                  required: true,
                  label: '保单号码:',
                  disabled: true,
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item
              v-for="(config, index) in baseItemsConfig"
              :prop="config.modelKey"
              :key="index"
            >
              <TextInput
                :isText="!isEdit"
                @returnVal="getKeyToValue"
                :config="config"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                v-model="form[config.modelKey]"
              ></TextInput>
            </el-form-item>
          </TextInputContainer>
        </AssociationScrollView>
        <AssociationScrollView title="服务信息" name="1">
          <TextInputContainer>
            <el-form-item prop="serviceTypeKey">
              <TextInput
                :isText="!isEdit"
                v-model="form.serviceTypeKey"
                :config="{
                  label: '服务种类：',
                  type: 'select',
                  modelKey: 'serviceTypeKey',
                  option: this.dictList.sdServiceTypes,
                  modelTextKey: 'serviceTypeName',
                }"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
              ></TextInput>
            </el-form-item>
            <el-form-item prop="serviceProjectKey">
              <TextInput
                @returnVal="getKeyToValue"
                :isText="!isEdit"
                v-model="form.serviceProjectKey"
                :config="{
                  label: '服务项目：',
                  type: 'select',
                  modelKey: 'serviceProjectKey',
                  option: this.dictList.insServicePlanList,
                  modelTextKey: 'serviceProjectName',
                }"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
              ></TextInput>
            </el-form-item>
            <el-form-item prop="serviceAmount">
              <TextInput
                :isText="!isEdit"
                v-model="form.serviceAmount"
                :config="{
                  label: '服务金额：',
                  type: 'input',
                  isNum: true,
                  modelKey: 'serviceAmount',
                }"
              ></TextInput>
            </el-form-item>
          </TextInputContainer>
          <el-form-item prop="serviceStandard"
            ><TextInput
              :isText="!isEdit"
              v-model="form.serviceStandard"
              :config="{
                label: '服务标准：',
                type: 'textarea',
                modelKey: 'serviceStandard',
                width: '100%',
              }"
            ></TextInput
          ></el-form-item>
          <TextInputContainer>
            <el-form-item prop="expertId">
              <TextInput
                @handleSelectQuerySearchAsync="handleSelectSearchAsyncExpert"
                :querySearchAsync="querySearchAsyncExpert"
                :isText="!isEdit"
                v-model="form.designateExpert"
                :config="{
                  label: '指派专家：',
                  type: 'searchInput',
                  required: true,
                  modelKey: 'expertId',
                  modelTextKey: 'designateExpert',
                }"
              ></TextInput>
            </el-form-item>
            <el-form-item prop="serviceLocation">
              <TextInput
                :isText="!isEdit"
                v-model="form.serviceLocation"
                :config="{
                  label: '专家服务区域：',
                  type: 'input',
                  disabled: true,
                  placeholder: '系统自动填写',
                  modelKey: 'serviceLocation',
                }"
              ></TextInput>
            </el-form-item>
            <el-form-item prop="areasOfExpertise">
              <TextInput
                :isText="!isEdit"
                v-model="form.areasOfExpertise"
                :config="{
                  label: '专家擅长领域：',
                  type: 'input',
                  disabled: true,
                  placeholder: '系统自动填写',
                  modelKey: 'areasOfExpertise',
                }"
              ></TextInput>
            </el-form-item>
            <el-form-item prop="workContent">
              <TextInput
                :isText="!isEdit"
                v-model="form.workContent"
                :config="{
                  label: '专家擅长工作内容：',
                  type: 'input',
                  disabled: true,
                  placeholder: '系统自动填写',
                  modelKey: 'workContent',
                }"
              ></TextInput>
            </el-form-item>
            <el-form-item prop="contracts">
              <TextInput
                :isText="!isEdit"
                v-model="form.contracts"
                :config="{
                  label: '委托方：',
                  type: 'input',
                  modelKey: 'contracts',
                }"
              ></TextInput>
            </el-form-item>
            <el-form-item prop="institution">
              <TextInput
                :isText="!isEdit"
                v-model="form.institution"
                :config="{
                  label: '机构：',
                  type: 'input',
                  modelKey: 'institution',
                }"
              ></TextInput>
            </el-form-item>
            <el-form-item prop="serviceDate">
              <TextInput
                :isText="!isEdit"
                v-model="form.serviceDate"
                :config="{
                  label: '期望服务时间：',
                  type: 'datePicker',
                  required: true,
                  modelKey: 'serviceDate',
                }"
              ></TextInput>
            </el-form-item>
            <el-form-item prop="expertServiceAmount">
              <TextInput
                :isText="!isEdit"
                v-model="form.expertServiceAmount"
                :config="{
                  label: '专家服务金额：',
                  type: 'input',
                  modelKey: 'expertServiceAmount',
                }"
              ></TextInput>
            </el-form-item>
            <el-form-item prop="serviceTypesOfKey">
              <TextInput
                @returnVal="getKeyToValue"
                :isText="!isEdit"
                v-model="form.serviceTypesOfKey"
                :config="{
                  label: '服务类型：',
                  type: 'select',
                  modelKey: 'serviceTypesOfKey',
                  modelTextKey: 'serviceTypesOfValue',
                  option: this.dictList.ServiceTypesOf,
                }"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
              ></TextInput>
            </el-form-item>
            <el-form-item prop="serviceAddress">
              <TextInput
                :isText="!isEdit"
                v-model="form.serviceAddress"
                :config="{
                  label: '服务地址：',
                  type: 'input',
                  modelKey: 'serviceAddress',
                }"
              ></TextInput>
            </el-form-item>
          </TextInputContainer>
          <el-form-item prop="taskDescription"
            ><TextInput
              :isText="!isEdit"
              v-model="form.taskDescription"
              :config="{
                label: '任务描述：',
                type: 'textarea',
                modelKey: 'taskDescription',
                width: '100%',
              }"
            ></TextInput
          ></el-form-item>
          <el-form-item prop="serviceInfoRemark"
            ><TextInput
              :isText="!isEdit"
              v-model="form.serviceInfoRemark"
              :config="{
                label: '备注：',
                type: 'textarea',
                modelKey: 'serviceInfoRemark',
                width: '100%',
              }"
            ></TextInput
          ></el-form-item>

          <!-- <TextInputContainer>
              <el-form-item
                :prop="config.modelKey"
                v-for="(config, index) in serciceItemsConfig"
                :key="index"
              >
                <TextInput
                  :isText="!isEdit"
                  v-model="form.serviceManagerName"
                  v-if="config.type == 'slot'"
                  :config="config"
                >
                  <EmployeeSelect
                    v-model="form.serviceManagerId"
                    placeholder="请选择"
                    @changeChecked="carryOutData"
                  />
                </TextInput>
                <TextInput
                  v-else
                  :isText="!isEdit"
                  @returnVal="getKeyToValue"
                  :optionConfig="{
                    value: 'dictKey',
                    label: 'dictValue',
                  }"
                  :config="config"
                  v-model="form[config.modelKey]"
                >
                </TextInput>
              </el-form-item>
            </TextInputContainer> -->
          <!--  -->
          <!-- <div style="margin-top: 24px; max-width: 1200px">
              <el-table
                :data="form.sonReqList"
                style="width: 100%"
                :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
              >
                <el-table-column type="index" label="ID" width="55">
                  <template slot-scope="{ row, $index }">
                    <el-form-item>
                      {{ $index + 1 }}
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="serviceTermName" label="服务项名称">
                  <template slot-scope="{ row, $index }">
                    <el-form-item
                      :prop="'sonReqList.' + $index + '.serviceTermName'"
                      :rules="
                        {
                          required: true,
                          message: '请输入服务项名称',
                          trigger: 'change',
                        },
                      "
                    >
                      <TextInput
                        :isText="!isEdit"
                        v-model="row.serviceTermName"
                        :config="{
                          type: 'input',
                        }"
                      >
                      </TextInput>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="serviceContent" label="服务内容">
                  <template slot-scope="{ row }">
                    <el-form-item>
                      <TextInput
                        :isText="!isEdit"
                        v-model="row.serviceContent"
                        :config="{
                          type: 'input',
                        }"
                      >
                      </TextInput>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注">
                  <template slot-scope="{ row }">
                    <el-form-item>
                      <TextInput
                        :isText="!isEdit"
                        v-model="row.remark"
                        :config="{
                          type: 'input',
                        }"
                      >
                      </TextInput>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="isEdit"
                  prop="date"
                  label="操作"
                  width="100"
                >
                  <template slot-scope="{ $index }">
                    <el-form-item>
                      <el-button
                        @click="deleteRow($index)"
                        type="text"
                        style="color: #f64a2d"
                        >删除</el-button
                      >
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
              <div v-if="isEdit" @click="addService" class="table-add-btn">
                添加服务
              </div>
            </div> -->
        </AssociationScrollView>
        <!-- <AssociationScrollView title="影像记录" name="2">
            <FormListUpload
              :isEdit="isEdit"
              required
              title=""
              :limit="5"
              :limitSize="50"
              attachmentType="sdServiceFile"
              v-model="form.attachmentAddReqList"
            ></FormListUpload>
          </AssociationScrollView> -->
        <div v-if="!isEdit">
          <AssociationScrollView title="企业基本信息" name="2">
            <TextInputContainer>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.firmBasicInfo.positionConditionValue"
                  :config="{
                    label: '企业位置情况：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.firmBasicInfo.natureValue"
                  :config="{
                    label: '企业性质：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.firmBasicInfo.naturePeopleNumber"
                  :config="{
                    label: '企业人员规模(含劳务人员)：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.firmBasicInfo.mainBusiness"
                  :config="{
                    label: '企业主要业务/项目名称：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
              <!-- <el-form-item>
                  <TextInput
                    isText
                    v-model="form.firmBasicInfo.positionConditionName"
                    :config="{
                      label: '企业/项目地址：',
                      type: 'input',
                    }"
                  >
                  </TextInput>
                </el-form-item> -->
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.firmBasicInfo.industryName"
                  :config="{
                    label: '企业行业类型：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.firmBasicInfo.actualPeopleNumber"
                  :config="{
                    label: '企业实际员工人数：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.firmBasicInfo.superviseTypeValue"
                  :config="{
                    label: '重点监管类型：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.firmBasicInfo.otherDescription"
                  :config="{
                    label: '企业其他情况描述：',
                    type: 'textarea',
                    modelKey: 'taskDescription',
                    width: '100%',
                  }"
                ></TextInput>
              </el-form-item>
            </TextInputContainer>
          </AssociationScrollView>
        </div>
        <div v-if="!isEdit">
          <AssociationScrollView title="签到信息" name="3">
            <TextInputContainer>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.signInStatusName"
                  :config="{
                    label: '签到状态：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.signInTime"
                  :config="{
                    label: '签到时间：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.signInLocations"
                  :config="{
                    label: '签到地点：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.signInPicture"
                  :config="{
                    label: '签到图片：',
                    type: 'slot',
                  }"
                >
                  <div>
                    <img
                      v-if="form.signInPicture"
                      style="width: 104px; height: 104px"
                      :src="form.signInPicture"
                    />
                    <div v-else>暂时没有图片！</div>
                  </div>
                </TextInput>
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.signOutTime"
                  :config="{
                    label: '签出时间：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item>
                <TextInput
                  isText
                  v-model="form.signOutLocations"
                  :config="{
                    label: '签出地点：',
                    type: 'input',
                  }"
                >
                </TextInput>
              </el-form-item>
            </TextInputContainer>
          </AssociationScrollView>
        </div>
        <div v-if="!isEdit">
          <AssociationScrollView title="服务报告" name="4">
            <FormListUpload
              :isEdit="false"
              title="服务报告"
              attachmentType="sdServiceFile"
              v-model="form.attachmentAddReqList"
            ></FormListUpload>
          </AssociationScrollView>
        </div>
        <div v-if="!isEdit">
          <AssociationScrollView title="备注" name="5">
            <el-form-item>
              <TextInput
                isText
                v-model="form.remark"
                :config="{
                  label: '备注：',
                  type: 'textarea',
                  modelKey: 'remark',
                  width: '100%',
                }"
              ></TextInput>
            </el-form-item>
            <FormListUpload
              :isEdit="false"
              title="附件"
              attachmentType="sdServiceFile"
              v-model="form.remarkAttachment"
            ></FormListUpload>
          </AssociationScrollView>
        </div>
        <div v-if="!isEdit && form.reportStatus != 1">
          <AssociationScrollView title="审核" name="6">
            <TextInput
              v-model="form.examineResult"
              :config="{
                disabled: form.reportStatus != 2,
                label: '审核结果：',
                type: 'radio',
                modelKey: 'examineResult',
                modelTextKey: 'examineResult',
                option: [
                  { dictKey: 1, dictValue: '通过' },
                  { dictKey: 2, dictValue: '退回' },
                ],
              }"
              :optionConfig="{
                value: 'dictKey',
                label: 'dictValue',
              }"
            ></TextInput>
            <TextInput
              v-model="form.examineRemark"
              :config="{
                disabled: form.reportStatus != 2,
                label: '备注：',
                type: 'textarea',
                modelKey: 'examineRemark',
                width: '100%',
              }"
            ></TextInput>
          </AssociationScrollView>
        </div>
      </AssociationScroll>
    </el-form>
    <!-- 底部按钮 -->
    <div class="sd-bottom-btn">
      <el-button @click="back">返回</el-button>
      <el-button v-if="isEdit" type="primary" @click="submitForm"
        >保存</el-button
      >
      <el-button
        v-if="form.reportStatus == 2 && !isEdit"
        type="primary"
        @click="submitForm"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import TextInput from "@/components/SimpleTable/TextInput";
import TextInputContainer from "@/components/SimpleTable/TextInputContainer";
import FormListUpload from "@/components/FormListUpload/index.vue";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import {
  getPolicyListByBlurMan,
  addEditService,
  getExpertListBlurName,
  serviceAudit,
  getLiabilityInsurancePolicy,
} from "@/api/safeDuty.js";
import { dictionaryBatch } from "@/api/policy";
import { sdServiceTypes } from "../js/const.js";
import { verifyArryEmpty } from "../js/verify.js";
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AssociationScroll,
    AssociationScrollView,
    TextInput,
    TextInputContainer,
    FormListUpload,
    EmployeeSelect,
  },
  watch: {
    detailData(n) {
      if (n) {
        this.form = { ...this.form, ...n };
        this.getHandleSomeDictByNumAndIns();
        this.$forceUpdate();
      }
    },
  },
  data() {
    return {
      rules: {
        // attachmentAddReqList: [
        //   {
        //     validator: verifyArryEmpty,
        //     message: "请添加影像记录",
        //     trigger: "blur",
        //   },
        // ],
        serviceDate: [
          { required: true, message: "请选择期望服务时间", trigger: "change" },
        ],
        expertId: [
          { required: true, message: "请选择被指派专家", trigger: "change" },
        ],
        insuredName: [
          { required: true, message: "请选择被保险人名称", trigger: "change" },
        ],
        firmAddress: [
          { required: true, message: "请完善被保险人地址", trigger: "change" },
        ],
        policyId: [
          { required: true, message: "请选择被保单号码", trigger: "change" },
        ],
        businessAddressKey: [
          { required: true, message: "请完善生产经营地址", trigger: "change" },
        ],
        insuredEmployeeCount: [
          { required: true, message: "请完善投保员工人数", trigger: "change" },
        ],
        employeeCount: [
          { required: true, message: "请完善从业人员人数", trigger: "change" },
        ],
      },
      dictList: { policyNoList: [], insServicePlanList: [], sdServiceTypes },
      form: {
        insuredPhone: "",
        serviceAddress: "",
        serviceInfoRemark: "",
        areasOfExpertise: "",
        areasOfExpertiseValue: "",
        attachmentAddReqList: [
          // {
          //   attachmentName: "",
          //   attachmentType: "",
          //   bussId: "",
          //   fileName: "",
          //   fileType: "",
          //   id: "",
          //   sort: 0,
          //   url: "",
          // },
        ],
        businessAddressKey: "",
        businessAddressName: "",
        companyId: "",
        companyName: "",
        contracts: "",
        designateExpert: "",
        employeeCount: 0,
        examineRemark: "",
        examineResult: 0,
        expertId: "",
        expertServiceAmount: 0,
        firmBasicInfo: {
          actualPeopleNumber: "",
          industryName: "",
          industryTypeKey: "",
          industryTypeValue: "",
          mainBusiness: "",
          natureKey: "",
          natureName: "",
          naturePeopleNumber: "",
          natureValue: "",
          otherDescription: "",
          positionConditionKey: "",
          positionConditionName: "",
          positionConditionValue: "",
          superviseTypeKey: "",
          superviseTypeName: "",
          superviseTypeValue: "",
        },
        industryName: "",
        industryTypeKey: "",
        industryTypeValue: "",
        insServiceFlowList: [
          // {
          //   comments: "",
          //   description: "",
          //   id: 0,
          //   isDeleted: true,
          //   recordTime: "",
          //   recorderId: 0,
          //   recorderName: "",
          //   serviceId: 0,
          // },
        ],
        institution: "",
        insuredAddress: "",
        insuredEmployeeCount: "",
        insuredEmployeeOccupation: "",
        insuredId: "",
        insuredName: "",
        isNamed: "",
        isSafetyCertificationRequired: "",
        policyId: "",
        policyNo: "",
        remark: "",
        serviceAmount: "",
        serviceDate: "",
        serviceLocation: "",
        serviceNo: "",
        serviceProjectName: "",
        serviceStandard: "",
        serviceStatus: "",
        serviceTypeKey: "",
        serviceTypeName: "",
        serviceTypeValue: "",
        serviceTypesOf: "",
        serviceTypesOfKey: "",
        serviceTypesOfValue: "",
        signInLocations: "",
        signInPicture: "",
        signInStatus: "",
        signInStatusName: "",
        signOutLocations: "",
        signOutTime: "",
        sign_InTime: "",
        taskDescription: "",
        updateTime: "",
        workContent: "",
        workContentValue: "",

        // attachmentAddReqList: [],
        // businessAddressName: "",
        // createTime: "",
        // createUser: "",
        // employeeCount: "",
        // industryName: "",
        // industryTypeKey: "",
        // industryTypeValue: "",
        // firmAddress: "",
        // insuredEmployeeCount: "",
        // insuredEmployeeOccupation: "",
        // insuredId: "",
        // insuredName: "",
        // //
        // isNamed: "",
        // isSafetyCertificationRequired: "",
        // participants: "",
        // policyId: "",
        // policyNo: "",
        // remark: "",
        // serviceAddress: "",
        // serviceCost: "",
        // serviceDate: "",
        // serviceManagerId: "",
        // serviceManagerName: "",
        // serviceNo: "",
        // serviceProjectKey: "",
        // serviceProjectName: "",
        // serviceProjectValue: "",
        // serviceTypeKey: "",
        // serviceTypeName: "",
        // serviceTypeValue: "",
        // serviceTypesOf: "",
        // serviceTypesOfKey: "",
        // serviceTypesOfValue: "",
        // sonReqList: [
        //   // {
        //   //   createTime: "",
        //   //   remark: "",
        //   //   serviceContent: "",
        //   //   serviceId: "",
        //   //   serviceTermName: "",
        //   // },
        // ],
      },
      baseItemsConfig: [],
      serciceItemsConfig: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getDictionarySd();
    },
    setConfig() {
      this.baseItemsConfig = [
        {
          label: "生产经营地址：",
          type: "input",
          required: true,
          modelKey: "businessAddressName",
          //modelTextKey: "businessAddressName",
          disabled: true,
          placeholder: "系统自动填写",
          //option: this.dictList.OperationAddress,
        },
        {
          label: "从业人员人数：",
          type: "inputNumber",
          required: true,
          modelKey: "employeeCount",
          disabled: true,
        },
        {
          label: "投保员工人数：",
          type: "inputNumber",
          required: true,
          modelKey: "insuredEmployeeCount",
          disabled: true,
          placeholder: "系统自动填写",
        },
        {
          label: "投保员工工种：",
          type: "input",
          modelKey: "insuredEmployeeOccupation",
          disabled: true,
          placeholder: "系统自动填写",
        },
        {
          label: "是否被要求安监证明：",
          type: "radio",
          modelKey: "isSafetyCertificationRequired",
          disabled: true,
          option: [
            {
              dictValue: "是",
              dictKey: 1,
            },
            {
              dictValue: "否",
              dictKey: 2,
            },
          ],
        },
        {
          label: "是否记名：",
          type: "radio",
          modelKey: "isNamed",
          disabled: true,
          option: [
            {
              dictValue: "是",
              dictKey: 1,
            },
            {
              dictValue: "否",
              dictKey: 2,
            },
          ],
        },
        {
          label: "行业：",
          type: "select",
          modelKey: "industryTypeKey",
          modelTextKey: "industryTypeValue",
          disabled: true,
          placeholder: "系统自动填写",
          option: this.dictList.IndustryType,
        },
        {
          label: "保险公司：",
          type: "input",
          modelKey: "companyName",
          disabled: true,
          placeholder: "系统自动填写",
        },
        {
          label: "被保险人联系方式：",
          type: "input",
          modelKey: "insuredPhone",
        },
      ];
      // this.serciceItemsConfig = [
      //   {
      //     label: "服务种类：",
      //     type: "select",
      //     modelKey: "serviceTypeKey",
      //     option: this.dictList.ServiceType,
      //     modelTextKey: "serviceTypeName",
      //   },
      //   {
      //     label: "对应服务项目名称：",
      //     type: "select",
      //     modelKey: "serviceProjectKey",
      //     option: this.dictList.insServicePlanList,
      //     modelTextKey: "serviceProjectName",
      //   },
      //   {
      //     label: "服务负责人：",
      //     type: "slot",
      //     //slot:true
      //   },
      //   {
      //     label: "服务类型：",
      //     type: "select",
      //     modelKey: "serviceTypesOfKey",
      //     option: this.dictList.ServiceTypesOf,
      //     modelTextKey: "serviceTypesOf",
      //   },
      //   {
      //     label: "参与人数：",
      //     type: "inputNumber",
      //     modelKey: "participants",
      //   },
      //   {
      //     label: "服务时间：",
      //     type: "datePicker",
      //     modelKey: "serviceDate",
      //   },
      //   {
      //     label: "服务地点：",
      //     type: "input",
      //     modelKey: "serviceAddress",
      //   },
      //   {
      //     label: "备注：",
      //     type: "input",
      //     modelKey: "remark",
      //   },
      //   {
      //     label: "服务费用：",
      //     type: "input",
      //     modelKey: "serviceCost",
      //     isNum: true,
      //   },
      // ];
    },
    getDictionarySd() {
      let dictKeys = [
        //行业
        "IndustryType",
        "OperationAddress",
        "ServiceType",
        "ServiceTypesOf",
        "Duration",
        "ContractSubType",
        "ServiceProjectName",
      ];
      dictionaryBatch({
        codes: dictKeys.join(","),
      }).then((res) => {
        if (res && res.data) {
          dictKeys.forEach((key) => {
            this.$set(this.dictList, key, res.data[key] || []);
          });
        }
        this.setConfig();
      });
    },
    querySearchAsync(queryString, cb) {
      if (!queryString) {
        cb([]);
        return;
      }
      getPolicyListByBlurMan({ insuredName: queryString }).then(
        (res) => {
          let results = res.data.insurancePolicies.map((item) => {
            return {
              ...item,
              value: item.insuredName,
            };
          });
          cb(results);
        },
        () => {
          cb([]);
        }
      );
    },
    handleSelectSearchAsync(e) {
      //console.log(e);
      this.setPolicyData({}, true);
      this.form.policyId = "";
      this.form.policyNo = "";
      this.form.insuredId = e.firmId;
      this.form.firmAddress = e.firmAddress;
      this.form.insuredPhone = e.contactNumber;
      this.form.serviceAddress = e.firmAddress;
      this.dictList.policyNoList = e.policyInsureds.map((item) => {
        return {
          ...item,
          dictValue: item.policyNumber,
          dictKey: item.id,
        };
      });
    },
    querySearchAsyncExpert(queryString, cb) {
      if (!queryString) {
        cb([]);
        return;
      }
      getExpertListBlurName({ text: queryString }).then(
        (res) => {
          let results = res.data.map((item) => {
            return {
              ...item,
              value: item.name,
            };
          });
          cb(results);
        },
        () => {
          cb([]);
        }
      );
    },
    handleSelectSearchAsyncExpert(e) {
      this.form.expertId = e.id;
      this.carryOutExpertData(e);
    },
    getKeyToValue(res) {
      if (res.type == "policyNo") {
        console.log(res);
        this.form.serviceProjectName = "";
        this.form.serviceProjectKey = "";
        this.dictList.insServicePlanList = res.data.insServicePlanList.map(
          (item) => {
            return {
              ...item,
              dictValue: item.serviceName,
              dictKey: item.id,
            };
          }
        );
        this.setConfig();
        this.setPolicyData(res);
      } else if (res.type == "serviceProjectName") {
        this.carryOutServiceData(res.data);
      }
      if (res.comType == "select") {
        this.form[res.type] = res.data.dictValue;
      }
    },
    setPolicyData(res, init = false) {
      let keys = [
        ["businessAddressKey", "businessAddressKey"],
        ["businessAddressName", "businessAddressName"],
        ["employeeCount", "employeeCount"],
        ["isNamed", "isNamed"],
        ["isSafetyCertificationRequired", "isSafetyCertificationRequired"],
        ["insuredEmployeeOccupation", "insuredEmployeeOccupation"],
        ["industryTypeKey", "industryTypeKey"],
        ["industryTypeName", "industryTypeName"],
        ["isSafetyCertificationRequired", "isSafetyCertificationRequired"],
        ["insuredEmployeeCount", "insuredEmployeeCount"],
        ["companyName", "companyName"],
      ];
      keys.forEach((key) => {
        if (init) {
          this.$set(this.form, key[0], "");
        } else {
          this.$set(this.form, key[0], res.data[key[1]]);
        }
      });
    },
    carryOutData(e) {
      this.form.serviceManagerId = e.id;
      this.form.serviceManagerName = e.title;
    },
    // 通过服务项目带出数据
    carryOutServiceData(item = {}) {
      let keys = ["serviceAmount", "serviceStandard"];
      keys.forEach((key) => {
        if (item[key]) {
          this.$set(this.form, key, item[key]);
        } else {
          this.$set(this.form, key, "");
        }
      });
    },
    // 通过专家带出数据
    carryOutExpertData(item = {}) {
      let keys = [
        ["serviceLocation", "serviceLocation"],
        ["areasOfExpertise", "areasOfExpertise"],
        ["workContent", "workContent"],
        ["standardCharge", "expertServiceAmount"],
      ];
      keys.forEach((key) => {
        if (item[key[0]]) {
          if (key[0] == "serviceLocation") {
            let loString = [];
            let localDatas = JSON.parse(item[key[0]]);
            localDatas.forEach((item) => {
              loString.push(`${item.province}/${item.city || "---"}`);
            });
            this.$set(this.form, key[1], loString.join(" ,"));
          } else {
            this.$set(this.form, key[1], item[key[0]]);
          }
        } else {
          this.$set(this.form, key[1], "");
        }
      });
    },
    addService() {
      this.form.sonReqList.push({
        remark: "",
        serviceContent: "",
        serviceTermName: "",
      });
    },
    deleteRow(i) {
      this.form.sonReqList.splice(i, 1);
    },
    submitForm() {
      if (this.isEdit) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            addEditService(this.form).then((res) => {
              this.$message({
                type: "success",
                message: "成功!",
              });
              this.back();
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        let Audit = {
          examineRemark: this.form.examineRemark,
          examineResult: this.form.examineResult,
          id: this.form.id,
        };
        serviceAudit(Audit).then((res) => {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.back();
        });
      }
    },
    back() {
      this.$router.go(-1);
      // this.$router.replace({ path: this.$route.path });
    },
    //根据详情的被保险人和保单号码获取对应的字典值
    getHandleSomeDictByNumAndIns() {
      getLiabilityInsurancePolicy({ policyId: this.form.policyId }).then(
        (res) => {
          this.dictList.policyNoList = [
            {
              dictValue: res.data.policyNumber,
              dictKey: res.data.id,
            },
          ];
          this.dictList.insServicePlanList = res.data.insServicePlanList.map(
            (item) => {
              return {
                ...item,
                dictValue: item.serviceName,
                dictKey: item.id,
              };
            }
          );
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.table-add-btn {
  background: #ffffff;
  border: 1px solid #dee3e6;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #00bc0d;
  cursor: pointer;
}
.sd-bottom-btn {
  width: 100%;
  height: 80px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  z-index: 99;
}
.steps-right {
  padding: 9px;
  color: #333333;

  &-title {
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  &-title::before {
    content: "";
    height: 16px;
    width: 6px;
    background-color: #4278c9;
    display: block;
    margin-right: 10px;
    border-radius: 20px;
  }
  &-title::after {
    content: "";
    height: 1px;
    width: 64px;
    background-color: #cccccc;
    margin-left: 10px;
    display: block;
  }
  &-record {
    font-size: 12px;
    margin-top: 20px;
    &-item {
      display: flex;
      &-bar {
        width: 6px;
        height: auto;
        background-color: #e1e1e1;
        position: relative;
      }
      &-bar::before {
        content: "";
        width: 6px;
        height: 6px;
        display: flex;
        position: absolute;
        top: 0;
        left: -4px;
        border-radius: 50%;
        background-color: #b8b8b8;
        border: 4px solid #d7d8d8;
      }
      &-content {
        display: flex;
        flex-direction: column;
        height: fit-content;
        flex: 1;
        padding: 0 0 0 18px;
        white-space: normal;
        word-break: break-word;
      }
    }
    &-time {
      font-weight: 300;
    }
    &-remark {
      font-weight: 400;
    }
  }
}
.steps-right-record-item:last-child > .steps-right-record-item-bar {
  height: 0;
}
.steps-right-record-item:first-child > .steps-right-record-item-bar::before {
  background-color: #4278c9;
  border: 4px solid #c6d5ea;
}
</style>
